import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Manage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Manage',
    component: () => import('../views/Manage.vue'),
    redirect:"/home",
    children:[
      {path:'home',name:'Home',meta:{title:'淮电智慧云'},component:() => import('../views/Home.vue')},
      {path:'user',name:'User',component:() => import('../views/User.vue')},
      {path:'safeList',name:'SafeList',component:() => import('../views/SafeList.vue')},
      {path:'safeStudy',name:'SafeStudy',component:() => import('../views/SafeStudy.vue')},
      {path:'safeCheck',name:'SafeCheck',component:() => import('../views/SafeCheck.vue')},
      {path:'video',name:'Video',component:() => import('../views/Video.vue')},
      {path:'wenzhang',name:'WenZhang',component:() => import('../views/WenZhang.vue')},
      {path:'dept',name:'Dept',component:() => import('../views/Dept.vue')},
      {path:'position',name:'Position',component:() => import('../views/Position.vue')},
      { path: 'danger', name: 'Danger', component: () => import('../views/Danger.vue') },
      {path:'danger_pendingConfirm', name: 'DangerPendingConfirm', component: () => import('../views/Danger/DangerPendingConfirm.vue') },
      { path: 'danger_deptConfirm', name: 'DangerDeptConfirm', component: () => import('../views/Danger/DangerDeptConfirm.vue') },
      { path: 'danger_pendingProcessing', name: 'DangerPendingProcessing', component: () => import('../views/Danger/DangerPendingProcessing.vue') },
      { path: 'danger_pendingAcceptance', name: 'DangerPendingAcceptance', component: () => import('../views/Danger/DangerPendingAcceptance.vue') },
      { path: 'danger_completed', name: 'DangerCompleted', component: () => import('../views/Danger/DangerCompleted.vue') },
      { path: 'danger_rejected', name: 'DangerRejected', component: () => import('../views/Danger/DangerRejected.vue') },
      {path:'dangerType',name:'DangerType',component:() => import('../views/DangerType.vue')},
      {path:'order',name:'Order',component:() => import('../views/Order.vue')},
      {path:'advice',name:'Advice',component:() => import('../views/Advice.vue')},
      {path:'hangup',name:'HangUp',component:() => import('../views/HangUp.vue')},
      {path:'practice',name:'Practice',component:() => import('../views/Practice.vue')},
      {path:'coal',name:'Coal',component:() => import('../views/Coal.vue')},
      {path:'loginLog',name:'LoginLog',component:() => import('../views/LoginLog.vue')}
    ]
  },

  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta:{title:'淮电智慧云'},
    component: () => import('../views/Login.vue')
  },
  {
    path: '/ssp',
    name: 'SSP',
    meta:{title:'随手拍'},
    component: () => import('../views/SSP.vue')
  },
  {
    path: '/exam',
    name: 'Exam',
    meta:{title:'考试'},
    component: () => import('../views/Exam.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/dangerTemp',
    name: 'DangerTemp',
    meta:{title:'随手拍'},
    component: () => import('../views/DangerTemp.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;  // 根据路由的meta设置页面标题
  }
  next();
});

export default router
